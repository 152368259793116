import './App.css'

function App() {
  return (
    <div className="App h-screen w-screen text-white flex justify-between flex-col">
      <div className="Cover md:p-32 p-10">
        <h1 className="text-5xl font-redhat"><span className='font-bold'>unoff</span> group</h1>
        <p className="text-2xl mt-4">Railway automatics solutions.</p>
        <p className="text-2xl mt-4">Research work on the implementation of onboard automation systems.</p>
      </div>
      <div className="Footer pb-10 px-10 md:pb-32 md:px-32 text-2xl mt-auto">
        <p>Contact R&D representative: <a href="mailto:jakub.rekowski@unoff.pl" className="font-semibold">jakub.rekowski@unoff.pl</a></p>
      </div>
    </div>
  );
}

export default App;
